// src/services/LLM.js
import { OpenAI } from 'openai';

// const API_KEY = 'sk-proj-xfoLuX7UvevYLqkF8S64T3BlbkFJUusN8huXZ56nadZ6D0Lo';
// const API_KEY = 'sk-proj-xnoYLiExaVliuoeEqHdzT3BlbkFJcNg5vbeiHjrH4TebJrmr';
const API_KEY = 'sk-proj-Rzoc3QloIeCAKGS8pafgT3BlbkFJunEdDYsu6Ae9hoNR6Gvt';
const default_style = `A  legyen strukturált, részletes és gyakorlati lépéseket tartalmazzon.`
const personality = "Te vagy a világ legokosabb regisztrált dietetikus asszisztens."// Pretend you are the world’s smartest registered dietitian and make a seven-day vegetarian meal plan with 1,700 calories for a woman who wants to lose one pound per week.



const openai = new OpenAI({
  apiKey: API_KEY,
  dangerouslyAllowBrowser: true 
});


export const translateText = async (text, targetLanguage) => {
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        { role: "system", content: "You are a highly skilled translator." },
        { role: "user", content: `Translate the following text to ${targetLanguage}: "${text}"` }
      ],
      max_tokens: 1000,
    });

    return response.choices[0].message.content.trim();
  } catch (error) {
    console.error('Error in translateText:', error);
    return `Translation error: ${error.message}`;
  }
};

export const queryDiaCoach = async (fixText, customInfo, selectedMessages, setDiacoachMessage, set) => {

  const dinamicQuery = (selectedMessages.length>0 ? `Behivatkozzuk ezeket az üzeneteket: \n${
    selectedMessages.map(message => message.text).filter(Boolean).join('\n')}\n\n` : '' +
    customInfo.length>0 ? `Az instrukció: ${customInfo}` : '' +
    `${fixText}`).trim() 
  console.log(dinamicQuery);
  if (dinamicQuery == '') { return }
  const fullQuery = dinamicQuery + default_style;

  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4o",
      messages: [
        { role: "system", content: personality },
        { role: "user",   content: fullQuery }],
      stream: true,
    });

    let partialResponse = '';

    for await (const chunk of response) {
      const content = chunk.choices[0]?.delta?.content || '';
      if (content) {
        partialResponse += content;
        set({ currentResponse: partialResponse });
        await setDiacoachMessage(partialResponse);
      }
    }
    

    return partialResponse;
 } catch (error) {
    console.error('Error in queryDiaCoach:', error);
    set({ currentResponse: `Hiba történt: ${error.message}` });
    await setDiacoachMessage(`Hiba történt: ${error.message}`);
    return `Hiba történt: ${error.message}`;
  }
};

export const translateConversation = async (messages, targetLanguage) => {
  try {
    // Find messages that need translation and keep track of original positions
    const messagesForTranslation = messages.map((msg, index) => ({
      ...msg,
      index, // Keep track of original position
      needsTranslation: !msg.translation
    }));

    // If all messages are translated, return empty array
    if (!messagesForTranslation.some(msg => msg.needsTranslation)) {
      return [];
    }

    // Take last 6 messages that include at least one untranslated message
    const lastUntranslatedIndex = messagesForTranslation.findIndex(msg => msg.needsTranslation);
    const contextMessages = messagesForTranslation.slice(Math.max(0, lastUntranslatedIndex - 3));
    const baseIdx = messages.length - contextMessages.length;
    console.log('Translating messages:', contextMessages)

    // Format conversation for translation with original message numbers
    const conversationText = contextMessages
      .map((msg, idx) => 
        `<${msg.userId === 'assistant' ? 'assistant' : 'user'}-${idx + 1}>${msg.text}</${msg.userId === 'assistant' ? 'assistant' : 'user'}-${msg.index + 1}>`
      )
      .join('\n');

    const prompt = `Translate the following conversation to ${targetLanguage}. 
Maintain the XML-like tags with their numbers and preserve formatting.
Only translate the text content, keep any markdown formatting intact.

${conversationText}

Respond with translations wrapped in corresponding numbered tags with '-translated' suffix.
Example format: <user-1-translated>translation</user-1-translated><assistant-1-translated>translation</assistant-1-translated>`;

    const response = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        { role: "system", content: "You are a highly skilled translator who translates user chat communications. Also you preserve message structure and formatting." },
        { role: "user", content: prompt }
      ],
      max_tokens: 2000,
    });

    const translatedText = response.choices[0].message.content.trim();
    console.log('translatedText:', translatedText)
    
    // Parse translations back into message format
    const translations = [];
    const regex = /<(user|assistant)-(\d+)-translated>([\s\S]*?)<\/\1-\2-translated>/g;
    let match;
    
    while ((match = regex.exec(translatedText)) !== null) {
      const messageIndex = parseInt(match[2]) - 1 + baseIdx;
      const originalMessage = messages[messageIndex];
      
      if (originalMessage && !originalMessage.translation) {
        translations.push({
          messageId: originalMessage.id,
          translation: match[3].trim(),
          originalIndex: messageIndex
        });
      }
    }

    // Sort translations by original message order
    return translations.sort((a, b) => a.originalIndex - b.originalIndex);
  } catch (error) {
    console.error('Error in translateConversation:', error);
    return [];
  }
};

// Más LLM-mel kapcsolatos funkciók itt...