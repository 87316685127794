
import { create } from 'zustand';
import { ref, onValue, get as getDatabase } from "firebase/database";
import { doc, getDoc } from 'firebase/firestore';
import { realtimeDB, firestoreDB } from '../services/firebase';
import { queryDiaCoach, translateText, translateConversation } from '../LLM/LLM';
import useChatsStore from './chatsStore';

// Example message structure:
// {
//   id: "90LeFrQRUr3Fph8gor3g",          // Firestore document ID
//   isLectored: true,                     // Whether message has been reviewed
//   text: "Köszönöm az információt!...",  // Message content
//   timestamp: Timestamp {               // Firestore timestamp
//     seconds: 1732546422, 
//     nanoseconds: 111000000
//   },
//   userId: "assistant",                  // Sender ID (still only 'assistant' value)
//   translation?: "Thank you...",         // Optional translated text
//   sender?: "uid"                        // Optional sender ID for chat messages, if not assistant 
// }
const useChatStore = create((set, get) => ({
  uid: null,
  selectedMessages: [],
  selectedMessageIds: new Set(),
  customInfo: '',
  currentResponse: '',
  diacoachMessage: '',
  setDiacoachMessage: (message) => set({ diacoachMessage: message }),
  isAutoTranslate: false,

  setSelectedMessages: (selectedMessages) => set({ selectedMessages }),
  setSelectedMessageIds: (ids) => set(state => ({ 
    selectedMessageIds: typeof ids === 'function' ? ids(state.selectedMessageIds) : new Set(ids) 
  })),
  
  setCustomInfo: (info) => set({ customInfo: info }),
  setCurrentResponse: (response) => set({ currentResponse: response }),
  saveUid: uid => set({ uid }),
  setAutoTranslate: (value) => set({ isAutoTranslate: value }),

  clearSelectedMessages: () => {
    set({ selectedMessages: [], selectedMessageIds: new Set() });
  },

  getUserLanguage: () => {
    const { profile } = get();
    return profile && profile.language ? profile.language.toLowerCase().slice(0, 2) : 'en';
  },

  clearChat: () => set({ 
    uid: null,
    selectedMessages: [], 
    selectedMessageIds: new Set(),
    isAutoTranslate: false,
  }),

  queryLLMDiaCoach: async (fixText) => {
    const { customInfo, selectedMessages, setDiacoachMessage } = get();
    const response = await queryDiaCoach(fixText, customInfo, selectedMessages, setDiacoachMessage, set);
    set({ customInfo: '', currentResponse: '' });
  },

  fetchAutoTranslateStatus: async (uid) => {
    try {
      const chatDoc = await getDoc(doc(firestoreDB, `chats/${uid}`));
      if (chatDoc.exists()) {
        const chatData = chatDoc.data();
        set({ isAutoTranslate: chatData.isAutoTranslate || false });
      }
    } catch (error) {
      console.error("Error fetching auto translate status:", error);
      set({ isAutoTranslate: false });
    }
  },

  initializeChat: async (uid) => {
    set({ uid });
    try {
      const chatDoc = await getDoc(doc(firestoreDB, `chats/${uid}`));
      if (chatDoc.exists()) {
        const chatData = chatDoc.data();
        set({ isAutoTranslate: chatData.isAutoTranslate || false });
      }
    } catch (error) {
      console.error("Error fetching chat settings:", error);
    }
  },
}));

export default useChatStore;
