
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ChatHeader from './ChatHeader';
import {MessageListForUid} from './MessageList';
import InputArea from './InputArea';
import Sidebar from './Sidebar';
import AssistantControls from './AssistantControls';
import useChatStore from '../store/chatStore';

const ChatWindowContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100vw;
`;

const ChatArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #2c2c2c;  // Changed from #1e1e1e to match sidebar
  height: 100%;
`;

const ChatWindow = () => {
  const { uid } = useParams();
  const { 
    initializeChat,
    clearChat
  } = useChatStore();

  useEffect(() => {
    if (uid) {
      initializeChat(uid);
    }

    return () => {
      clearChat();
    };
  }, [uid, initializeChat, clearChat]);

  return (
    <ChatWindowContainer>
      <Sidebar />
      <ChatArea>
        {uid ? (
          <>
            <ChatHeader uid={uid} />
            <MessageListForUid uid={uid} />
            {/* <AssistantControls /> */}
            <InputArea />
          </>
        ) : (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            color: '#a0a0a0'
          }}>
            Select a chat to start messaging
          </div>
        )}
      </ChatArea>
    </ChatWindowContainer>
  );
};

export default ChatWindow;
