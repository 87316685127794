import React from 'react';
import { create } from 'zustand';
import { ref, get as getDatabase } from "firebase/database";
import { realtimeDB } from '../services/firebase';

const useHealthStore = create((set, get) => ({
  healthData: {},

  setHealthData: (uid, data) => set(state => ({
    healthData: { ...state.healthData, [uid]: data }
  })),

  fetchHealthData: async (uid) => {
    // Return cached data if available
    const cached = get().healthData[uid];
    if (cached) return cached;

    try {
      const healthRef = ref(realtimeDB, `users/${uid}/health`);
      const snapshot = await getDatabase(healthRef);
      
      if (snapshot.exists()) {
        const healthData = snapshot.val();
        get().setHealthData(uid, healthData);
        return healthData;
      }
    } catch (error) {
      console.error('Error fetching health data:', error);
    }
    return null;
  },

  clearHealthData: () => set({ healthData: {} })
}));

// Custom hook with memoization
export const useHealthData = (uid) => {
  const { healthData, fetchHealthData } = useHealthStore();

  React.useEffect(() => {
    if (uid && !healthData[uid]) {
      fetchHealthData(uid);
    }
  }, [uid]);

  return healthData[uid] || null;
};

export default useHealthStore;
