import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import ChatListItem from './ChatListItem';
import useChatStore from '../store/chatStore';
import useChatsStore from '../store/chatsStore';

const SidebarContainer = styled.div`
  width: 300px;
  background-color: #2c2c2c;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 20px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const SidebarTitle = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #e74c3c;
  }
`;

const SearchInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  background-color: #333;
  color: white;
  margin: 10px;
  font-size: 14px;
`;

const ChatList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

const ScrollList = styled.div`
  /* Modern scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const Sidebar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const { clearSelectedMessages } = useChatStore();
  const { chats, fetchChats, removeChatsListener } = useChatsStore();

  const filteredChats = useMemo(() => 
    chats.filter(d => !searchTerm || d.uid.toLowerCase().includes(searchTerm.toLowerCase())),
    [chats, searchTerm]
  );

  useEffect(() => {
    if (currentUser) {
      fetchChats();
    }
    return () => removeChatsListener();
  }, [currentUser, fetchChats, removeChatsListener]);

  const handleChatSelect = (uid) => {
    clearSelectedMessages();
    navigate(`/chat/${uid}`);
  };


  const Row = ({ index, style }) => {
    const chat = filteredChats[index];
    return (
      <div style={style}>
        <ChatListItem
          uid={chat.uid}
          lectored={chat.data.lectored}
          searchTerm={searchTerm}
          onSelect={() => handleChatSelect(chat.uid)}
        />
      </div>
    );
  };

  return (
    <SidebarContainer>
      <SearchInput
        placeholder="Search chat..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <ListWrapper>
        <ScrollList>
          <FixedSizeList
            height={window.innerHeight - 100}
            itemCount={filteredChats.length}
            itemSize={70}
            width="100%"
          >
            {Row}
          </FixedSizeList>
        </ScrollList>
      </ListWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
