import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import ChatWindow from './components/ChatWindow';
import TestCasesView from './components/TestCasesView';
import EvalsView from './components/EvalsView';
import Navigation from './components/Navigation';
import PromptsView from './components/PromptsView';
import Login from './components/Login';
import { useAuth } from './contexts/AuthContext';
import useChatStore from './store/chatStore';
import useChatsStore from './store/chatsStore';
import { cleanup as cleanupMessages } from './store/messagesStore';
import useProfileStore from './store/profileStore';
import useHealthStore from './store/healthStore';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #1e1e1e;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

function App() {
  const { currentUser } = useAuth();
  const { clearChat } = useChatStore();
  const { fetchChats, removeChatsListener, clearChats } = useChatsStore();

  useEffect(() => {
    if (currentUser) {
      fetchChats();
    }

    return () => {
      removeChatsListener();
      clearChats();
      clearChat();
      cleanupMessages();
      useProfileStore.getState().clearProfiles();
      useHealthStore.getState().clearHealthData();
    };
  }, [currentUser, fetchChats, removeChatsListener, clearChats, clearChat]);

  return (
    <Router>
      <GlobalStyles />
      {currentUser ? (
        <AppContainer>
          <Navigation />
          <ContentContainer>
            <Routes>
              <Route path="/chat/:uid" element={<ChatWindow />} />
              <Route path="/chat" element={<ChatWindow />} />
              <Route path="/testcases" element={<TestCasesView />} />
              <Route path="/evals" element={<EvalsView />} />
              <Route path="/prompts" element={<PromptsView />} />
              <Route path="/" element={<Navigate to="/chat" replace />} />
            </Routes>
          </ContentContainer>
        </AppContainer>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;