import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { formatName } from '../utils';
import { useMessages } from '../store/messagesStore';
import useChatStore from '../store/chatStore';
import { useProfileData } from '../store/profileStore';

const ChatItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;

  &:hover {
    background-color: #3a3a3a;
  }
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
`;

const ChatInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 1;
`;

const ChatNameRow = styled.div`
  display: flex;
  align-items: center;
`;

const ChatName = styled.span`
  font-weight: ${props => props.$allLectored ? '400' : '900'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

const LastMessage = styled.span`
  font-size: 0.8em;
  color: #d4d4d4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  font-weight: ${props => props.$isLectored ? '300' : '600'};
`;

const MessageCount = styled.span`
  background-color: #3498db;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: 8px;
  flex-shrink: 0;
`;

const ChatListItem = ({ uid, searchTerm, onSelect }) => {
  const messages = useMessages(uid);
  const profile = useProfileData(uid);  // Use the new profile hook

  const lastMessage = useMemo(() => 
    messages.length > 0 ? messages[messages.length - 1].text : 'No messages yet'
  , [messages]);

  const isLectored = useMemo(() => 
    messages.length > 0 ? messages[messages.length - 1].isLectored : true
  , [messages]);

  const allLectored = useMemo(() => 
    messages.length > 0 ? messages.every(msg => msg.isLectored) : true
  , [messages]);

  const messageCount = useMemo(() => 
    messages.length
  , [messages]);

  const displayName = formatName(profile) || uid;

  return (
    <ChatItem onClick={onSelect}>
      <Avatar src={profile?.photo || `${process.env.PUBLIC_URL}/50.png`} alt={displayName} />
      <ChatInfo>
        <ChatNameRow>
          <ChatName $allLectored={allLectored}>{displayName}</ChatName>
          {/* <MessageCount>{messageCount}</MessageCount> */}
        </ChatNameRow>
        <LastMessage $isLectored={isLectored}>{lastMessage}</LastMessage>
      </ChatInfo>
    </ChatItem>
  );
};

export default ChatListItem;
