import { create } from 'zustand';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { firestoreDB } from '../services/firebase';

const useChatsStore = create((set, get) => ({
  chats: [],
  chatsListener: null,

  fetchChats: () => {
    const q = query(
      collection(firestoreDB, 'chats'),
      orderBy('lastrequest', 'desc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chatsData = querySnapshot.docs.map(doc => ({
        uid: doc.id,
        data: doc.data()
      }));
      set({ chats: chatsData });
    });

    set({ chatsListener: unsubscribe });
    return unsubscribe;
  },

  removeChatsListener: () => {
    const { chatsListener } = get();
    if (chatsListener) {
      chatsListener();
      set({ chatsListener: null });
    }
  },

  clearChats: () => set({ 
    chats: [], 
    chatsListener: null,
  }),
}));

export default useChatsStore;
