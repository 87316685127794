import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { firestoreDB, sendNotificationToUid } from '../services/firebase';
import { useAuth } from '../contexts/AuthContext';
import useChatStore from '../store/chatStore';

const InputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 12px 16px;
  background-color: #2c2c2c;
  border-top: 1px solid #3a3a3a;
`;

const TextAreaWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  background-color: #3a3a3a;
  border-radius: 20px;
  padding: 8px 16px;
  margin-right: 12px;
`;
const TextArea = styled.textarea`
  width: 100%;
  border: none;
  background-color: transparent;
  color: #fff;
  min-height: 24px;
  max-height: 450px;
  overflow-y: auto;
  font-size: 16px;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
 -webkit-font-smoothing: antialiased;
  line-height: 1.5;

  &:focus {
    outline: none;
  }

  /* Custom scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #6b6b6b #3a3a3a;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #3a3a3a;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
    border-radius: 4px;
    border: 2px solid #3a3a3a;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }
`;
const SendButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2980b9;
  }
`;

const SendIcon = styled.span`
  font-size: 20px;
`;

const TestButton = styled.button`
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  corner-radius: 16px,
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
`;

const InputArea = () => {
  const { uid, diacoachMessage, setDiacoachMessage, getUserLanguage } = useChatStore();
  const { currentUser } = useAuth();
  const textareaRef = useRef(null);

  const handleTestFunction = async () => {
    console.log('handleTestFunction:', handleTestFunction)
    try {
      // const testFunction = httpsCallable(functions, 'testFunction');
      const result = await sendNotificationToUid(uid, );
      // const result = await testFunction({ test: 'data' });
      console.log('Test function result:', result);
    } catch (error) {
      // console.error('Error calling test function:', error);
    }
  };

  const handleSend = async () => {
    if (diacoachMessage.trim() && currentUser) {
      try {
        // Debug log to see the message structure
        const messageData = {
          text: diacoachMessage,
          timestamp: serverTimestamp(),
          userId: currentUser.uid,  
          isLectored: true,
        };
        console.log("Sending message with data:", messageData);

        // Add message to Firestore
        const docRef = await addDoc(collection(firestoreDB, `chats/${uid}/messages`), messageData);
        console.log("Document written with ID: ", docRef.id);

        // Attempt to send notification
        try {
          console.log("Attempting to send notification...");
          const userLanguage = getUserLanguage();
          const result = await sendNotificationToUid(uid, userLanguage);
          console.log("Notification sent successfully:", result);
        } catch (notificationError) {
          console.error("Error sending notification:", notificationError);
        }

        setDiacoachMessage('');
        textareaRef.current.style.height = 'auto';
      } catch (error) {
        console.error("Error in handleSend:", error);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && document.activeElement === textareaRef.current) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleTextAreaChange = (e) => {
    setDiacoachMessage(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  useEffect(() => {
    if (textareaRef.current){
      textareaRef.current.focus();
    }}, [textareaRef.current]);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 200)}px`;
    }
  }, [textareaRef.current, diacoachMessage]);
  
  return (
    <InputContainer>
      <TextAreaWrapper>
        <TextArea 
          ref={textareaRef}
          value={diacoachMessage}
          onChange={handleTextAreaChange}
          onKeyDown={handleKeyDown}
          placeholder="Type a message... (Markdown supported)"
          rows={1}
        />
      </TextAreaWrapper>
      <SendButton onClick={handleSend}>
        <SendIcon>➡️</SendIcon>
      </SendButton>
    </InputContainer>
  );
};

export default InputArea;
