import styled from 'styled-components';

// Az üzenet vagy a chat tulajdonosé (user) vagy asszisztens üzenet
export const isAssistantMessage = (message, chatOwnerId) =>
  message?.sender !== chatOwnerId && message?.userId !== chatOwnerId;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.$isAssistant ? 'row-reverse' : 'row'};
  justify-content: flex-start;
  margin: 8px 0;
`;

export const MessageBubble = styled.div`
  padding: 12px 16px;
  margin: 5px 0;
  max-width: 80%;
  color: white;
  background: ${props => props.$isAssistant ? '#0084ff' : '#1a7f4b'};  // Assistant is blue, user is green
  border-radius: 12px;
  position: relative;
`;

export const MessageTime = styled.div`
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 4px;
`;

export const SelectionIndicator = styled.div`
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f39c12;
  opacity: ${({ $isSelected }) => $isSelected ? 1 : 0};
  transition: opacity 0.2s;
`;
