import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  color: white;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  background-color: #2c2c2c;
  padding: 12px;
  text-align: left;
  border-bottom: 2px solid #3a3a3a;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #3a3a3a;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #2c2c2c;
  }
`;

const EvalsView = () => {
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <Th>Run ID</Th>
            <Th>Test Case</Th>
            <Th>Status</Th>
            <Th>Creation Time</Th>
          </tr>
        </thead>
        <tbody>
          {/* Evaluation data will be added later */}
        </tbody>
      </Table>
    </Container>
  );
};

export default EvalsView;
