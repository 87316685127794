import React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #1e1e1e;
  padding: 5px 20px;
  border-bottom: 1px solid #3a3a3a;
  height: 32px;
`;

const NavButtons = styled.div`
  display: flex;
`;

const NavButton = styled.button`
  background-color: ${props => props.$active ? '#3498db' : 'transparent'};
  color: white;
  border: none;
  padding: 4px 12px;
  margin-right: 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.$active ? '#2980b9' : '#3a3a3a'};
  }
`;

const LogoutButton = styled(NavButton)`
  margin-left: auto;
  &:hover {
    background-color: #e74c3c;
  }
`;

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const currentPath = location.pathname.split('/')[1] || 'chat';

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  return (
    <NavContainer>
      <NavButtons>
        <NavButton 
          $active={currentPath === 'chat'} 
          onClick={() => navigate('/chat')}
        >
          Chat
        </NavButton>
        <NavButton 
          $active={currentPath === 'testcases'} 
          onClick={() => navigate('/testcases')}
        >
          Testcases
        </NavButton>
        <NavButton 
          $active={currentPath === 'evals'} 
          onClick={() => navigate('/evals')}
        >
          Evals
        </NavButton>
        <NavButton 
          $active={currentPath === 'prompts'} 
          onClick={() => navigate('/prompts')}
        >
          Prompts
        </NavButton>
      </NavButtons>
      <LogoutButton onClick={handleLogout}>
        <span role="img" aria-label="logout">⎋</span>
      </LogoutButton>
    </NavContainer>
  );
};

export default Navigation;
