import React from 'react';
import { create } from 'zustand';
import { ref, get as getDatabase } from "firebase/database";
import { realtimeDB } from '../services/firebase';

const useProfileStore = create((set, get) => ({
  profiles: {},

  setProfile: (uid, profile) => set(state => ({
    profiles: { ...state.profiles, [uid]: profile }
  })),

  fetchProfile: async (uid) => {
    // Return cached data if available
    const cached = get().profiles[uid];
    if (cached) return cached;

    try {
      const profileRef = ref(realtimeDB, `users/${uid}/profile`);
      const snapshot = await getDatabase(profileRef);
      
      if (snapshot.exists()) {
        const profileData = snapshot.val();
        get().setProfile(uid, profileData);
        return profileData;
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
    return null;
  },

  clearProfiles: () => set({ profiles: {} })
}));

// Custom hook with memoization
export const useProfileData = (uid) => {
  const { profiles, fetchProfile } = useProfileStore();

  React.useEffect(() => {
    if (uid && !profiles[uid]) {
      fetchProfile(uid);
    }
  }, [uid]);

  return profiles[uid] || null;
};

export default useProfileStore;
