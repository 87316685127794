import { create } from 'zustand';
import { useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { firestoreDB } from '../services/firebase';
import { translateConversation } from '../LLM/LLM';

// Global singleton for managing listeners and cache
const MessageManager = {
  messageListeners: new Map(),
  
  getMessages(uid) {
    return useMessagesStore.getState().messages[uid] || [];
  },

  hasListener(uid) {
    return this.messageListeners.has(uid);
  },

  setupListener(uid) {
    if (this.hasListener(uid)) return;

    const q = query(
      collection(firestoreDB, `chats/${uid}/messages`),
      orderBy('timestamp')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      useMessagesStore.getState().setMessages(uid, messages);
    });

    this.messageListeners.set(uid, unsubscribe);
    return unsubscribe;
  },

  removeListener(uid) {
    const unsubscribe = this.messageListeners.get(uid);
    if (unsubscribe) {
      unsubscribe();
      this.messageListeners.delete(uid);
    }
  },

  clearAll() {
    this.messageListeners.forEach(unsubscribe => unsubscribe());
    this.messageListeners.clear();
    useMessagesStore.getState().clearMessages();
  }
};

export async function translateAllMessages(uid, targetLanguage) {
  if (!uid) return;
  const messages = useMessagesStore.getState().messages[uid];
  try {
    const translations = await translateConversation(messages, targetLanguage);
    
    // Update Firestore and store in parallel
    await Promise.all(translations.map(async ({ messageId, translation }) => {
      await updateDoc(doc(firestoreDB, `chats/${uid}/messages/${messageId}`), { translation });
    }));

    // Update store with translations
    const updatedMessages = messages.map(msg => {
      const translation = translations.find(t => t.messageId === msg.id)?.translation;
      return translation ? { ...msg, translation } : msg;
    });
    
    useMessagesStore.getState().setMessages(uid, updatedMessages);
  } catch (error) {
    console.error("Error translating messages:", error);
  }
}

const useMessagesStore = create((set) => ({
  messages: {},
  setMessages: (uid, messages) => set(state => ({
    messages: { ...state.messages, [uid]: messages }
  })),
  
  clearMessages: () => set({ messages: {} }),
  
  updateMessage: (uid, messageId, updates) => set(state => ({
    messages: {
      ...state.messages,
      [uid]: (state.messages[uid] || []).map(message =>
        message.id === messageId ? { ...message, ...updates } : message
      )
    }
  }))
}));

export const useMessages = (uid) => {
  const messages = useMessagesStore(state => state.messages[uid] || []);

  useEffect(() => {
    if (!uid) return;
    MessageManager.setupListener(uid);
  }, [uid]);

  // Return from cache directly to avoid unnecessary re-renders
  return messages;
};

export const useMessageActions = () => {
  const { updateMessage } = useMessagesStore();
  return { updateMessage };
};

export const cleanup = () => MessageManager.clearAll();

export default useMessagesStore;
