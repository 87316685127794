import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { collection, query, orderBy, addDoc, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { firestoreDB } from '../services/firebase';

const Container = styled.div`
  padding: 20px;
  color: white;
  width: 100%;
  display: flex;
  gap: 20px;
`;

const PromptList = styled.div`
  flex: 1;
  max-width: 300px;
`;

const PromptEditor = styled.div`
  flex: 2;
`;

const PromptItem = styled.div`
  padding: 10px;
  margin-bottom: 8px;
  background-color: #2c2c2c;
  border-radius: 4px;
  cursor: pointer;
  border-left: 3px solid ${props => props.active ? '#3498db' : 'transparent'};

  &:hover {
    background-color: #3a3a3a;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 400px;
  background-color: #2c2c2c;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px;
  font-family: monospace;
  resize: vertical;
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #2980b9;
  }
`;

const StatusBadge = styled.span`
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 3px;
  margin-left: 8px;
  background-color: ${props => props.tested ? '#e74c3c' : '#2ecc71'};
`;

const PromptsView = () => {
  const [prompts, setPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [newPromptText, setNewPromptText] = useState('');

  useEffect(() => {
    const q = query(
      collection(firestoreDB, 'systemprompts'),
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const promptsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPrompts(promptsData);
    });

    return () => unsubscribe();
  }, []);

  const handleCreateNewPrompt = async () => {
    if (!newPromptText.trim()) return;

    try {
      await addDoc(collection(firestoreDB, 'systemprompts'), {
        content: newPromptText,
        timestamp: serverTimestamp(),
        tested: false,
        version: prompts.length + 1
      });
      setNewPromptText('');
      setSelectedPrompt(null);
    } catch (error) {
      console.error('Error creating prompt:', error);
    }
  };

  return (
    <Container>
      <PromptList>
        <Button 
          onClick={() => setSelectedPrompt(null)}
          disabled={!selectedPrompt}
        >
          Create New
        </Button>
        {prompts.map(prompt => (
          <PromptItem 
            key={prompt.id}
            active={selectedPrompt?.id === prompt.id}
            onClick={() => setSelectedPrompt(prompt)}
          >
            v{prompt.version}
            <StatusBadge tested={prompt.tested}>
              {prompt.tested ? 'Tested' : 'Draft'}
            </StatusBadge>
          </PromptItem>
        ))}
      </PromptList>
      
      <PromptEditor>
        <TextArea
          value={selectedPrompt ? selectedPrompt.content : newPromptText}
          onChange={(e) => selectedPrompt ? null : setNewPromptText(e.target.value)}
          disabled={selectedPrompt?.tested}
          placeholder="Write your system prompt here..."
        />
        {!selectedPrompt && (
          <Button onClick={handleCreateNewPrompt}>
            Save New Prompt
          </Button>
        )}
      </PromptEditor>
    </Container>
  );
};

export default PromptsView;
