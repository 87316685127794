import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { collection, query, onSnapshot, orderBy, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { firestoreDB } from '../services/firebase';
import { useMessages } from '../store/messagesStore';
import { MessageList } from './MessageList';

const Container = styled.div`
  padding: 20px;
  color: white;
  width: 100%;
  height: calc(100vh - 32px); // Navigációs sáv magasságát levonjuk
  overflow: auto;
  box-sizing: border-box; // Hogy a padding ne adódjon hozzá a magassághoz

  /* Modern scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 8px;
`;

const Th = styled.th`
  background-color: #2c2c2c;
  padding: 12px;
  text-align: left;
  border-bottom: 2px solid #3a3a3a;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #3a3a3a;
  overflow: hidden;
  cursor: pointer;

  &.message-text {
    max-width: 300px;
    white-space: pre-wrap;
    max-height: 100px;
    overflow-y: auto;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: #3a3a3a;
    }

    /* Modern scrollbar for message cell */
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.3) transparent;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 3px;
      transition: background-color 0.2s;

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  &.condition-cell {
    min-width: 400px;
    max-width: 600px;
    width: 50%;
  }

  &.relevancy-cell {
    width: 120px;
  }

  &.editable:hover {
    background-color: #3a3a3a;
  }
`;

const EditableInput = styled.textarea`
  background-color: #3a3a3a;
  border: 1px solid #4a4a4a;
  color: white;
  padding: 8px;
  width: 100%;
  min-height: 60px;
  max-height: 200px;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.4;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: #2c2c2c;
  padding: 10px;
  border-radius: 4px;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  white-space: pre-wrap;
  word-break: break-word;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #2c2c2c;
  }
`;


const MessageHistory = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 1000;
  min-width: 60%;

  /* Modern scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::after {
    content: '(ESC)';
    font-size: 12px;
  }
`;

const RelevancySelect = styled.select`
  background-color: #3a3a3a;
  color: ${props => {
    switch (props.value) {
      case 'important': return '#ff6b6b';
      case 'normal': return '#ffffff';
      case 'note': return '#aaaaaa';
      default: return '#ffffff';
    }
  }};
  border: 1px solid #4a4a4a;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 120px;
  font-weight: ${props => props.value === 'important' ? 'bold' : 'normal'};

  &:focus {
    outline: none;
    border-color: #3498db;
  }

  option {
    background-color: #2c2c2c;
    color: white;
    font-weight: normal;
  }
`;

const ConditionCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ConditionText = styled.div`
  flex: 1;
  color: ${props => {
    switch (props.relevancy) {
      case 'important': return '#ff6b6b';
      case 'normal': return '#ffffff';
      case 'note': return '#aaaaaa';
      default: return '#ffffff';
    }
  }};
  font-weight: ${props => props.relevancy === 'important' ? 'bold' : 'normal'};
`;

const ConditionContent = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 1.4;
  max-height: 80px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const DeleteButton = styled.button`
  background-color: transparent;
  color: #ff6b6b;
  border: none;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 16px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 107, 107, 0.1);
    transform: scale(1.1);
  }
`;

const DeleteModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #2c2c2c;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 400px;
  color: white;
`;

const DeleteInput = styled.input`
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  background-color: #3a3a3a;
  border: 1px solid #4a4a4a;
  border-radius: 4px;
  color: white;

  &:focus {
    outline: none;
    border-color: #ff6b6b;
  }
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 16px;
`;

const ModalButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => props.delete ? '#ff6b6b' : '#6b6b6b'};
  color: white;
  opacity: ${props => props.disabled ? 0.5 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  &:hover:not(:disabled) {
    background-color: ${props => props.delete ? '#ff5252' : '#5a5a5a'};
  }
`;

const TestCasesView = () => {
  const [testCases, setTestCases] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [tooltip, setTooltip] = useState({ visible: false, text: '', x: 0, y: 0 });
  const [deleteModal, setDeleteModal] = useState({ visible: false, testCase: null });
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const messages = useMessages(selectedChat?.chatId);
  const messageHistoryRef = useRef(null);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setSelectedChat(null);
      }
    };

    if (selectedChat) {
      document.addEventListener('keydown', handleEsc);
    }

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [selectedChat]);

  useEffect(() => {
    if (selectedChat && messageHistoryRef.current) {
      messageHistoryRef.current.scrollTop = messageHistoryRef.current.scrollHeight;
    }
  }, [selectedChat, messages]);

  useEffect(() => {
    const q = query(
      collection(firestoreDB, 'testcases'),
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const cases = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTestCases(cases);
    });

    return () => unsubscribe();
  }, []);

  const handleMessageClick = (testCase) => {
    setSelectedChat(testCase);
  };

  const relevantMessages = messages
    .slice(0, messages.findIndex(m => m.id === selectedChat?.messageId) + 1);

  const handleEditCondition = async (testCase, newCondition) => {
    try {
      await updateDoc(doc(firestoreDB, 'testcases', testCase.id), {
        condition: newCondition
      });
      setEditingId(null);
    } catch (error) {
      console.error('Error updating condition:', error);
    }
  };

  const handleRelevancyChange = async (testCase, newRelevancy) => {
    try {
      await updateDoc(doc(firestoreDB, 'testcases', testCase.id), {
        relevancy: newRelevancy
      });
    } catch (error) {
      console.error('Error updating relevancy:', error);
    }
  };

  const handleShowTooltip = (text, e) => {
    // Only show tooltip if text is longer than 100 characters
    if (text.length > 100) {
      setTooltip({
        visible: true,
        text,
        x: e.clientX,
        y: e.clientY
      });
    }
  };

  const handleHideTooltip = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  const handleDelete = async (testCase, e) => {
    e.stopPropagation();
    setDeleteModal({ visible: true, testCase });
  };

  const confirmDelete = async () => {
    if (deleteConfirmation === 'yes' && deleteModal.testCase) {
      try {
        await deleteDoc(doc(firestoreDB, 'testcases', deleteModal.testCase.id));
        setDeleteModal({ visible: false, testCase: null });
        setDeleteConfirmation('');
      } catch (error) {
        console.error('Error deleting test case:', error);
      }
    }
  };

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <Th>Message</Th>
            <Th>Condition</Th>
            <Th>Relevancy</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {testCases.map(testCase => (
            <Tr key={testCase.id}>
              <Td 
                className="message-text"
                onMouseEnter={(e) => handleShowTooltip(testCase.messageText, e)}
                onMouseLeave={handleHideTooltip}
                onClick={() => handleMessageClick(testCase)}
              >
                {testCase.messageText || 'N/A'}
              </Td>
              <Td 
                className="condition-cell editable"
                onClick={() => setEditingId(testCase.id)}
              >
                {editingId === testCase.id ? (
                  <EditableInput
                    defaultValue={testCase.condition}
                    onBlur={(e) => handleEditCondition(testCase, e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && e.shiftKey) {
                        handleEditCondition(testCase, e.target.value);
                      } else if (e.key === 'Escape') {
                        setEditingId(null);
                      }
                    }}
                    autoFocus
                    onClick={(e) => e.stopPropagation()}
                  />
                ) : (
                  <ConditionContent
                    onMouseEnter={(e) => handleShowTooltip(testCase.condition, e)}
                    onMouseLeave={handleHideTooltip}
                  >
                    {testCase.condition}
                  </ConditionContent>
                )}
              </Td>
              <Td className="relevancy-cell">
                <RelevancySelect
                  value={testCase.relevancy || 'normal'}
                  onChange={(e) => handleRelevancyChange(testCase, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <option value="note">Note</option>
                  <option value="normal">Normal</option>
                  <option value="important">Important</option>
                </RelevancySelect>
              </Td>
              <Td>
                <DeleteButton 
                  onClick={(e) => handleDelete(testCase, e)}
                  title="Delete test case"
                >
                  🗑️
                </DeleteButton>
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>

      {deleteModal.visible && (
        <DeleteModal>
          <h3>Delete Test Case</h3>
          <p>Are you sure you want to delete this test case? This action cannot be undone.</p>
          <p>Type 'yes' to confirm:</p>
          <DeleteInput
            type="text"
            value={deleteConfirmation}
            onChange={(e) => setDeleteConfirmation(e.target.value)}
            placeholder="Type 'yes' to confirm"
            autoFocus
          />
          <ModalButtons>
            <ModalButton onClick={() => {
              setDeleteModal({ visible: false, testCase: null });
              setDeleteConfirmation('');
            }}>
              Cancel
            </ModalButton>
            <ModalButton
              delete
              disabled={deleteConfirmation !== 'yes'}
              onClick={confirmDelete}
            >
              Delete
            </ModalButton>
          </ModalButtons>
        </DeleteModal>
      )}

      {tooltip.visible && (
        <Tooltip style={{ top: tooltip.y + 20, left: tooltip.x }}>
          {tooltip.text}
        </Tooltip>
      )}

      {selectedChat && (
        <MessageHistory ref={messageHistoryRef}>
          <CloseButton 
            onClick={() => setSelectedChat(null)}
            title="Close message history (Press ESC)"
          >
            ×
          </CloseButton>
          <MessageList
            messages={relevantMessages}
            selectedMessageId={selectedChat?.messageId}
            uid={selectedChat?.chatId}
          />
        </MessageHistory>
      )}
    </Container>
  );
};

export default TestCasesView;
